import { Container, Stack } from "react-bootstrap";
import { Skills } from "../components/Skills";
import { Resume } from "../components/Resume";
import { About } from "../components/About";
import { Contact } from "../components/Contact";

export function Home() {
  return (
    <>
      <Container>
        <Stack gap={4} style={{marginTop:30}}>
          <h1>tomjellis.dev</h1>
          <About/>
          <Skills />
          <Resume />
          <Contact />
        </Stack>
      </Container>
    </>
  );
}
