import { Container, Stack } from "react-bootstrap";

export function About(){
    return(
        <Container>
            <h2>hey I'm Tom</h2>
            <Container>
            <Stack>
                <p>What's up?</p>    
            </Stack>
            </Container>
        </Container>
    )
}