import { Container, Stack } from "react-bootstrap";

export function Contact() {
  return (
    <Container>
      <h2>ways to connect:</h2>
      <Container>
        <Stack direction="horizontal" gap={3}>
          <i onClick={()=>window.open("https://www.github.com/tomjellis", "_blank")} title="github" className="bi-github socials"></i>
          <i onClick={()=>window.open("https://www.linkedin.com/in/tomjellis", "_blank")} title="linkedin" className="bi-linkedin socials"></i>
          <i onClick={()=>window.open("https://www.instagram.com/tomfrommelbourne", "_blank")} title="instagram" className="bi-instagram socials"></i>
          <i onClick={()=>window.open("https://www.buymeacoffee.com/tomjellis", "_blank")} title="buy me a coffee" className="bi-cup-hot-fill socials"></i>
        </Stack>
      </Container>
    </Container>
  );
}
