import { Container } from "react-bootstrap";
import skills from "../data/skills.json";
import MarqueeText from "react-marquee-text";
import { useEffect, useState } from "react";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function Skills() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [skillChunks, setSkillChunks] = useState([]);
  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    const shuffledSkills = shuffle(skills);
    const perChunk = Math.ceil(shuffledSkills.length / 3); // Round up to ensure all skills are displayed
  
    const chunks = shuffledSkills.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
  
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
  
      resultArray[chunkIndex].push(item);
  
      return resultArray;
    }, []);
  
    setSkillChunks(chunks)
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container className="skills-section">
      <h2>things I have used:</h2>
      <Container>
      {skillChunks.map((chunk, index) => (
        <MarqueeText key={index} direction={index % 2 === 0 ? "left" : "right"} duration={((index+1) * screenWidth / 20)}>
          {chunk.map((skill, skillIndex) => (
            <i
              key={skillIndex}
              className={"devicon-" + skill + "-plain skill-icon"}
              title={skill}
            ></i>
          ))}
        </MarqueeText>
      ))}
      </Container>
    </Container>
  );
}
