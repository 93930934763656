import { Container, Stack } from "react-bootstrap";

export function Resume() {
  return (
    <Container>
      <h2>things I am doing:</h2>
      {/* <Stack direction="horizontal" gap={5}>
        <Card>
          <Card.Title>Associate Engineer @ NAB</Card.Title>
        </Card>
        <Card>
          <Card.Title>Chair of RoboCup SPL Organising Committee</Card.Title>
        </Card>
        <Card>
          <Card.Title>
            Team Member of RMIT's RedbackBots
          </Card.Title>
        </Card>
        <Card>
          <Card.Title>
            Bachelor of Computer Science @ RMIT
          </Card.Title>
        </Card>
      </Stack> */}
      <Container>
        <Stack gap={3}>
          <h4>Associate Engineer @ NAB</h4>
          <h4>Chair of RoboCup SPL Organising Committee</h4>
          <h4>Team Member of RMIT's RedbackBots</h4>
          <h4>Bachelor of Computer Science @ RMIT</h4>
        </Stack>
      </Container>
    </Container>
  );
}
